@import '../styles/Colors.module.sass';

$icon-height: 300px;

@mixin flex-center-all {
  display: flex;
  align-items: center;
  justify-content: center; }

.hero {
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(255, 255, 255, 0)), url('../static/pexels-ezequiel-da-silva-2902541.jpg');
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-position: center;
  @include flex-center-all;
  flex-flow: column;
  color: white;
  padding: 0px 250px;
  font-size: 64px;
  font-family: 'Akira';

  .logo {
    height: $icon-height;
    width: $icon-height;
    @include flex-center-all;

    border: 20px solid white;
    position: relative;
    font-size: 100px;

    .number {
      position: absolute;
      top: 5px;
      right: 10px;
      font-size: 30px; } }

  input {
    color: white; }

  a {
    margin-top: 40px;
    font-family: "Segoe UI", sans-serif;
    font-size: 21px; } }

