@font-face {
  font-family: 'ITC Avant Garde Gothic Bold';
  font-weight: 900;
  src: local('ITC Avant Garde Gothic Bold'), url("../fonts/ITC Avant Garde Gothic Bold.otf") format('opentype'); }

@font-face {
  font-family: 'Akira';
  font-weight: 500;
  src: local('Akira Expanded Demo'), url("../fonts/Akira Expanded Demo.otf") format('opentype'); }

@font-face {
  font-family: 'Azonix';
  font-weight: 500;
  src: local('Azonix'), url("../fonts/Azonix.otf") format('opentype'); }

* {
  box-sizing: border-box;
  border: none;
  padding: 0;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

body {
  position: relative;
  font-family: "Segoe UI", sans-serif;
  font-size: 16px; }

h2 {
  margin: 0;
  font-size: 2.25rem;
  font-weight: bold;
  letter-spacing: -0.025em; }

li {
  list-style: none; }

a {
  color: inherit;
  text-decoration: none; }

a:hover {
  opacity: 1;
  text-decoration: none;
  cursor: pointer; }

button {
  padding: 8px 30px;
  background: transparent;
  border: 1px solid black;
  border-radius: 10px;
  /* antialiased border */
  box-shadow: 0 0 1px 0px black inset, 0 0 1px 0px black;
  cursor: pointer;
  outline: none; }

input[type=text] {
  padding: 8px;
  background: transparent;
  border: 1px solid black;
  outline: none; }

.flexPullRight {
  margin-left: auto; }

.flexPullLeft {
  margin-right: auto; }

.flexPullBottom {
  margin-top: auto; }

.main {
  width: 100%;
  padding: 0px 250px;
  display: flex;
  flex-flow: column; }

.title {
  font-size: 35px;
  font-weight: 100;
  font-family: "ITC Avant Garde Gothic Bold"; }

.all {}
