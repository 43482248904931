@import "../styles/Colors.module";

$icon-height: 50px;

@mixin flex-center-all {
  display: flex;
  align-items: center;
  justify-content: center; }

.passthrough {
  position: absolute; }

.navigationBar {
  width: 100%;
  height: 75px;
  display: flex;
  align-items: center;
  padding: 0px 250px;
  color: white;

  nav {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;

    a {
      height: 100%;
      @include flex-center-all; }

    span {
      margin: 0px 45px; } }

  .homeLink {
    display: block;
    height: $icon-height;

    .logo {
      height: $icon-height;
      width: $icon-height;
      @include flex-center-all;

      border: 2px solid white;
      position: relative;
      font-size: 21px;

      &::after {
        content: '14';
        position: absolute;
        top: 2px;
        right: 2px;
        font-size: 10px; } } } }
